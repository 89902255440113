<template>
  <div
    v-if="metadata"
    class="h-full overflow-y-auto border-l border-gray-700"
    :class="parent == 'dashboard' ? 'bg-gray-800/25' : 'bg-gray-900'"
  >
    <div class="space-y-2 px-4 py-2" :class="parent == 'dashboard' ? 'bg-gray-800/25' : 'bg-gray-900'">
      <div class="flex justify-between space-x-2">
        <p class="break-words text-sm font-semibold text-gray-100">
          {{ metadata.headline }}
        </p>
        <div class="flex-shrink-0" v-if="parent === 'dashboard'">
          <BaseButton type="ghost" size="xs" @press="resetData">
            <div class="flex items-center space-x-1">
              <span class="block text-xs">Hide </span><IconChevronRight size="h-3 w-3" />
            </div>
          </BaseButton>
        </div>
      </div>
      <p class="break-words text-xs font-medium text-gray-200">{{ metadata.details }}</p>
    </div>
    <div
      v-for="(dataset, idx) in metadata.datasets.filter(x => x.is_chart)"
      :key="idx"
      class="border-t border-gray-700"
      :class="parent == 'dashboard' ? 'bg-gray-800/25' : 'bg-gray-900'"
    >
      <p class="break-words px-4 py-2 text-sm font-semibold text-gray-100">{{ dataset.content.title }}</p>
      <div class="h-64 w-full px-2">
        <Chart
          :id="Math.random()"
          :options="{ ...dataset.content.config, series: dataset.content.data }"
          class="h-full w-full"
        />
      </div>
    </div>
    <div
      v-for="(dataset, idx) in metadata.datasets.filter(x => !x.is_chart)"
      :key="idx"
      class="border-t border-gray-700"
      :class="parent == 'dashboard' ? 'bg-gray-800/25' : 'bg-gray-900'"
    >
      <p class="px-4 py-2 text-sm font-semibold text-gray-100">{{ dataset.content.title }}</p>
      <div class="h-64 w-full py-2">
        <BaseTable
          id="metadata-table"
          class="h-full"
          :column-defs="dataset.content.config"
          :row-data="dataset.content.data"
          :default-col-def="{
            flex: 1,
            sortable: false,
            tooltipComponent: AgGridTooltip
          }"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { Chart } from 'highcharts-vue';
import { ref, nextTick, onMounted } from 'vue';
import Highcharts from 'highcharts/highcharts';
import AgGridTooltip from '@/components/ag_grid/AgGridTooltip.vue';
import AgGridLinkCell from '@/components/ag_grid/AgGridLinkCell.vue';
import colors from 'tailwindcss/colors';
import { baseHighchartsTheme } from '@/components/chart/utils/ChartBaseTheming';

Highcharts.setOptions(baseHighchartsTheme());
const props = defineProps({
  rawMetadata: {
    type: Object,
    default: () => {}
  },
  selectedRow: {
    type: Object
  },
  resetMetadata: {
    type: Function
  },
  parent: {
    type: String
  }
});
function dispatchResizeEvent() {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 200);
}
onMounted(() => {
  transformedData();
});
//METADATA
const metadata = ref(null);
const transformedData = () => {
  const selectedProp = props.parent == 'signal' ? props.rawMetadata : props.selectedRow.data;
  metadata.value = {
    headline: selectedProp?.headline,
    details: selectedProp?.body,
    datasets: []
  };

  nextTick(() => {
    selectedProp.metadata?.forEach(m => {
      metadata.value.datasets.push({
        is_chart: m.type != 'table',
        type: m.type.includes('chart') ? m.type.split('_')[0] : m.type,
        content: {
          title: m.params.title,
          config: m.type == 'table' ? getTableOptions(m) : getChartOptions(m),
          data: m.type == 'table' ? getTableData(m) : getChartData(m)
        }
      });
    });
    //HACK: to adjust highcharts to full width
    dispatchResizeEvent();
  });
};
function getTableData(meta) {
  return meta?.data || [];
}
function getChartOptions(meta) {
  const type = meta.type.split('_')[0];
  let options = {
    tooltip: { shared: true, xDateFormat: '%b %d, %Y %l:%M %p' },
    chart: {
      id: `on-chain-signals-${Math.random()}`,
      type: type == 'bar' ? 'column' : type,
      backgroundColor: '#161e31'
    },
    colors: [colors.blue[600], colors.red[400], colors.amber[600], colors.green[400]],
    xAxis: { type: 'datetime', title: { text: meta.params.x_axis } },
    yAxis: []
  };
  const yAxis = meta.params.y_axis.split(',');
  yAxis.forEach((y, i) => {
    options.yAxis.push({ title: { text: y }, labels: { format: '{text}' }, opposite: i % 2 == 1 });
  });
  return options;
}
function getChartData(meta) {
  const yAxis = meta.params.y_axis.split(',');
  let series = [];
  yAxis.forEach((y, i) => {
    const data = meta.data
      .map(x => {
        return [new Date(x[meta.params.x_axis]).getTime(), parseFloat(x[y.trim()])];
      })
      .sort((a, b) => a[0] - b[0]);
    series.push({ yAxis: i, name: y, data: data });
  });
  return series;
}
function getTableOptions(meta) {
  let config = [];
  const firstDataObject = meta?.data[0];
  if (!firstDataObject) {
    return null;
  }
  for (const [key, value] of Object.entries(firstDataObject)) {
    config.push({
      headerName: key,
      cellRenderer: value.includes('https') ? AgGridLinkCell : null,
      tooltipComponentParams: { html: true },
      tooltipValueGetter: params => {
        const val = params?.data[key] || '';
        if (val.includes('https')) {
          return `<div class="w-40"><div class="text-xs break-all">${val}</div></div>`;
        }
        return val;
      },
      field: key,
      minWidth: 150
    });
  }
  return config;
}

const resetData = () => {
  if (props.parent === 'dashboard') props.selectedRow.setSelected(false);
  props.resetMetadata();
};
</script>
